import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"

const Blog = () => {
  const article = articles.find(art => art.id === "3")

  return (
    <ArticleSection>
      <ArticleContainer>
        <ArticleTextContainer>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>Publié le {article.date}</ArticleDate>

          <ArticleImageContainer>
            <img
              src={article.image}
              alt={article.title}
              className="articleImage"
            ></img>
          </ArticleImageContainer>
          <ArticleSubTible>Le rituel des Samouraïs</ArticleSubTible>
          <ArticleIntro>{article.intro}</ArticleIntro>
          <ArticleText>
            Il faut savoir que cette pratique suicidaire n’était utilisée qu’en
            dernier recours, et selon des circonstances précises : soit en cas
            de défaite, dans ce cas le samouraï optait pour le suicide afin de
            ne pas salir le nom de son seigneur ; soit lorsqu’un vassal
            critiquait ouvertement le Shogun (général), il préférait alors se
            suicider pour conserver son honneur et attirer son attention.{" "}
          </ArticleText>
          <ArticleSubTible>L’esthétique japonaise</ArticleSubTible>
          <ArticleText>
            Dans la culture japonaise, l’esthétique occupe une place
            essentielle. Même si cette notion peut paraître lointaine de celle
            que nous connaissons en Occident, le Hara-kiri est toutefois
            considéré comme une véritable esthétique du suicide et de la mort.
            Il est perçu comme un geste précis, délicat et audacieux. Ce sens
            esthétique est bien entendu retrouvé dans la gastronomie japonaise.
            Le dressage d’un ramen par exemple sera toujours soigné avec
            quelques touches de couleurs pour rendre le plat le plus appétissant
            possible. Chez Hara-Kiri nous y accordons beaucoup d’importance.
          </ArticleText>
          <ArticleSubTible>L’art du tatouage</ArticleSubTible>
          <ArticleText>
            À l’époque des Samouraïs, le tatouage occupait une place essentielle
            dans leur appartenance à un clan puisqu’ils se faisaient tatouer
            systématiquement leur emblème. Le tatouage va ensuite être interdit
            sous le règne de Mutsuhito puis va reprendre mais uniquement comme
            marquage punitif chez les criminels. Les Yakuzas, qui sont les
            membres de la mafia japonaise et qui se revendiquent comme les
            samouraïs des temps modernes, ont ensuite perpétré cette tradition.
            Ils sont ainsi connus pour avoir le corps recouvert de tatouages et
            faire de cette distinction un signe d’appartenance à leur
            communauté. Aujourd’hui, le tatouage reste mal perçu au Japon en
            raison de cette assimilation à la criminalité mais il existe
            toutefois une véritable culture du tatouage pouvant être perçu comme
            un art. Chez Hara-Kiri, nous sommes fascinés par l’art du tatouage
            japonais, et plus précisément du tatouage traditionnel appelé
            irezumi. C’est la raison pour laquelle nos murs sont décorés de
            portraits de Samouraïs tatoués.
          </ArticleText>
          <ArticleSubTible>Le choix du nom Hara-Kiri</ArticleSubTible>
          <ArticleText>
            Pour toutes ces raisons, c’est le nom Hara-Kiri que nous avons
            choisi pour représenter notre cantine. Du mur de saké coloré
            jusqu’aux t-shirts de nos serveurs en passant par le dressage de nos
            ramens, vous retrouverez toute l’esthétique emblématique du Japon,
            le tout dans un univers de samouraïs tatoués sur un fond de musique
            rock indé. Pas d’inquiétude, Chez Hara-Kiri, aucune raison de
            s’ouvrir le ventre, en revanche, après avoir dégusté un bon ramen,
            on parie que votre ventre sera bien rempli ! Vous serez alors Hara
            ooki.
          </ArticleText>
        </ArticleTextContainer>
      </ArticleContainer>
    </ArticleSection>
  )
}

const ArticleSection = styled.section`
  margin-top: 100px;
  padding: 0 10%;
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  /* padding: 20px 60px; */
  margin: auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
`
const ArticleIntro = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`

const ArticleSubTible = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

const ArticleText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: #fff;
  margin-bottom: 40px;

  .articleImage {
    width: 100%;
    height: 50%;
    object-fit: cover;
    height: 400px;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
