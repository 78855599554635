import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article3 from "../components/Article3"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO title="Pourquoi Hara-Kiri ?" />
    <Article3 />
  </Layout>
)

export default IndexPage
